import type Loading from '../../services/LoadingSpinner/Loading';
import type { ColorMode } from '../../services/Theme/ThemeContext';
import type { User } from '../../services/User/User';
import type { Inspection, TestingRound } from '../../utils/lists';
import type { ErrorRespose } from '../../utils/response';
import type { AxiosError, AxiosResponse } from 'axios';
import type { Country } from 'country-code-lookup';
import type { Dayjs } from 'dayjs';
import type { MouseEvent } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Avatar, Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material';
import MuiCard from '@mui/material/Card';
import { grey } from '@mui/material/colors';
import lookup from 'country-code-lookup';
import Holidays from 'date-holidays';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import dayjs from '../../dayjsConfig';
import { get } from '../../services/Api/ApiFunctions';
import useLoadingSpinnerContext from '../../services/LoadingSpinner/LoadingSpinnerService';
import { useColorMode } from '../../services/Theme/ThemeContext';
import useUser from '../../services/User/UserService';
import { brand } from '../../theme/theme';

import InspectionDetailDialog from '../dialogs/InspectionDetailDialog/InspectionDetailDialog';
import { Status } from '../../utils/statuses';

interface DayItem {
  number: number,
  day: string,
  isCurrentDay: boolean,
  isHoliday: boolean,
}

interface TableCalendarProps {
  isOpenCalendar: boolean
}

const daysWeek: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const StyledCard: any = styled(MuiCard)(() => ({
  ':hover': {
    border: '1px solid',
    cursor: 'pointer',
  },
}));

function TableCalendar({ isOpenCalendar }: TableCalendarProps): JSX.Element {
  const user: User = useUser();
  const theme: ColorMode = useColorMode();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [days, setDays] = useState<DayItem[]>([]);
  const [openUserInfoDialog, setOpenUserInfoDialog] = useState(false);
  const [inspections, setInspections] = useState<Inspection[] | undefined>();
  const [dialogInspection, setDialogInspection] = useState<Inspection>();
  const country: Country | null = lookup.byCountry(user.active_role?.supplier?.country ?? '');
  const hd: Holidays = new Holidays(country?.iso2 ?? '');

  const populateDays = (date: string): void => {
    const startDate: Dayjs = dayjs(date);
    let dates: DayItem[] = [];
    daysWeek.forEach((dayWeek: string, index: number) => {
      dates = [...dates, {
        number: startDate.day(index + 1).date(),
        day: dayWeek,
        isCurrentDay: dayjs().startOf('date').format() === startDate.add(index,'day').startOf('date').format(),
        isHoliday: Boolean(hd.isHoliday(startDate.add(index,'day').toDate())),
      }];
    });
    setDays(dates);
  };

  const getFilters = (): URLSearchParams => {
    const filters: URLSearchParams = new URLSearchParams();
    const week: number = dayjs().week();
    const year: number = dayjs().year();
    filters.set('from', searchParams.get('calendar_week') ?? dayjs().day(1).week(week).year(year).startOf('date').format());
    filters.set('to', searchParams.get('calendar_week') ?? dayjs().day(6).week(week).year(year).add(1,'day').startOf('date').format());

    if(searchParams.get('calendar_week') != null) {
      const filterweek: number = Number((searchParams.get('calendar_week') ?? '').split('W')[1]);
      const filteryear: number = Number((searchParams.get('calendar_week') ?? '').split('-')[0]);
      filters.set('from', dayjs().day(1).week(filterweek).year(filteryear).startOf('date').format());
      filters.set('to', dayjs().day(6).week(filterweek).year(filteryear).add(1,'day').startOf('date').format());
    }else{
      filters.delete('calendar_week');
    }

    if(searchParams.get('calendar_orderitemNumber') != null || searchParams.get('calendar_orderitemNumber') != '') {
      filters.set('orderitemNumber', searchParams.get('calendar_orderitemNumber') ?? '');
    }else{
      filters.delete('calendar_orderitemNumber');
    }

    if(searchParams.get('calendar_skuCode') != null) {
      filters.set('skuCode', searchParams.get('calendar_skuCode') ?? '');
    }else{
      filters.delete('calendar_skuCode');
    }

    if(searchParams.get('calendar_colorCode') != null) {
      filters.set('colorCode', searchParams.get('calendar_colorCode') ?? '');
    }else{
      filters.delete('calendar_colorCode');
    }

    if(searchParams.get('calendar_supplierId') != null) {
      filters.set('supplierId', searchParams.get('calendar_supplierId') ?? '');
    }else{
      filters.delete('calendar_supplierId');
    }

    if(searchParams.get('calendar_inspectorId') != null) {
      filters.set('inspectorId', searchParams.get('calendar_inspectorId') ?? '');
    }else{
      filters.delete('calendar_inspectorId');
    }

    if(searchParams.get('calendar_allDepartments') != null && user.active_role?.role === 'COORDINATOR') {
      filters.set('allDepartments', searchParams.get('calendar_allDepartments') ?? '');
    }else{
      filters.delete('calendar_allDepartments');
    }

    return filters;
  };

  const handleFetchInspections = (): void => {
    const filters: URLSearchParams = getFilters();
    populateDays(filters.get('from') ?? '');

    get<Inspection[]>('/inspections', filters)
      .then((res: AxiosResponse<Inspection[], any> | undefined): void => {
        if(res?.data !== undefined) {
          setInspections([...res.data]);
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  useEffect(() => {
    if(isOpenCalendar) {
      loadingSpinner.addRequest();
      handleFetchInspections();
      loadingSpinner.addResponse();
    }
  }, [isOpenCalendar, searchParams]);

  return (
    <Card sx={{ mx: 2, mb: 2, boxShadow: 3, borderRadius: '10px', flexGrow:1, overflow:'auto' }}>
      <TableContainer sx={{ height:'100%' }}>
        <Table size="small" stickyHeader style={{ tableLayout: 'fixed', borderSpacing: 0,height:'100%' }}>
          <TableHead>
            <TableRow>
              {days.map((day: DayItem, index: number) => {
                return day.isCurrentDay ?
                  <TableCell key={index} align="center">
                    <Box sx={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                      <Avatar sx={{ backgroundColor: theme.mode === 'light' ? '#e4e9f2' : brand[400] , color:theme.mode === 'light' ? '#000000' : '#FFFFFF' }}>
                        <Typography variant='h5'>{day.number}</Typography>
                      </Avatar>
                      <Typography variant='h5' color={theme.mode === 'light' ? '#000000' : '#FFFFFF'}>{t(`calendar_${day.day.toLowerCase()}_label`)}</Typography>
                    </Box>
                  </TableCell> :
                  <TableCell key={index} align="center">
                    <Box sx={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
                      <Avatar sx={{ backgroundColor: theme.mode === 'light' ? '#FFFFFF' : grey[600] }}>
                        <Typography variant='h6' color={theme.mode === 'light' ? grey[500] : grey[300]} >{day.number}</Typography>
                      </Avatar>
                      <Typography variant='h6' >{t(`calendar_${day.day.toLowerCase()}_label`)}</Typography>
                    </Box>
                  </TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height:'100%' }}>
            {inspections?.length !== 0 ?
              <TableRow sx={{ height:'100%' }}>
                {days.map((day: DayItem, indexCell: number) =>{
                  return (
                    <TableCell
                      key={indexCell}
                      align='center'
                      sx={{
                        height:'100%',
                        background: day.isCurrentDay ? theme.mode === 'light' ? grey[300] : brand[400] : day.isHoliday ? `repeating-linear-gradient(45deg, transparent, transparent 10px, ${grey[600]} 20px, ${brand[100]} 20px);` : '',
                        alignContent:'start',
                        '&:not(:first-of-type)': {
                          borderLeft: `1px solid ${theme.mode === 'light' ? grey[300] : grey[800]}`,
                        },
                      }}>
                      {inspections?.map((inspection: Inspection, index: number) => {
                        return day.number === dayjs(inspection.date).date() &&
                              <Box component='span' key={index} onClick={(): void => {
                                setOpenUserInfoDialog(!openUserInfoDialog);
                                setDialogInspection(inspection);
                              }}>
                                <StyledCard sx={{ mb: 2, p: 2, boxShadow: 2, backgroundColor:theme.mode === 'light' ? '#FFFFFF' : grey[900] , border:'1px solid', borderColor: theme.mode === 'light' ? grey[300] : grey[700] , borderRadius: '10px', display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center' }}>
                                  {Boolean(searchParams.get('calendar_allDepartments')) && <Typography>{t(`${String(inspection.department.code.toLowerCase())}_label`)}</Typography>}
                                  <Typography>
                                    {user.active_role?.role === 'COORDINATOR' && `${inspection.supplier.name}`}
                                    {user.active_role?.role === 'COORDINATOR' && <br />}
                                    {(user.active_role?.role === 'SUPPLIER' || user.active_role?.role === 'COORDINATOR') && `${inspection.inspector.name} ${inspection.inspector.surname}`}
                                  </Typography>
                                  <Box sx={{ display:'flex', gap:1, justifyContent:'center' }}>
                                    {inspection.reportUrl === null && <WatchLaterIcon />}
                                    {
                                      !inspection.testingRoundList.every(
                                        (testingRound: TestingRound) => testingRound.status === Status.DONE) ?
                                        inspection.isLocked
                                          ? <LockIcon /> : <LockOpenIcon /> : <></>}
                                  </Box>
                                  <Box sx={{ display:'flex', gap:1, justifyContent:'center' }}>
                                    {inspection.reportUrl !== null ?
                                      <div onClick={(e: MouseEvent<HTMLDivElement>): Window | null => {
                                        e.stopPropagation();

                                        return window.open(String(inspection.reportUrl), '_blank');
                                      }}>
                                        <PictureAsPdfIcon sx={{ my: 1 }} />
                                      </div> : null}
                                  </Box>
                                  <Typography>
                                    {`${dayjs(inspection.date).format('HH:mm')}`}
                                  </Typography>
                                </StyledCard>
                              </Box>;
                      })}
                    </TableCell>);
                },
                )}
              </TableRow>
              : <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center' }} key={-1}>
                  <Typography variant="h6" color='#8f9bb3'>{t('inspection_empty')}</Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <InspectionDetailDialog
        open={openUserInfoDialog}
        onClose={(): void => setOpenUserInfoDialog(false)}
        inspection={dialogInspection}
      />
    </Card>
  );
}

export default TableCalendar;
