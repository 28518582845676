import type { DownloadPdfResponse, ErrorRespose } from './response';
import type { AxiosError, AxiosResponse } from 'axios';

import { t } from 'i18next';
import { toast } from 'react-toastify';

import { get } from '../services/Api/ApiFunctions';

import storageType from './storageService';

export const checkDownloadPdf = (): void => {
  const params: URLSearchParams = new URLSearchParams();
  if(localStorage.getItem('download-pdf-department') != null && localStorage.getItem('download-pdf-scenario') != null && localStorage.getItem('download-pdf-date') != null) {
    params.set('department', localStorage.getItem('download-pdf-department') ?? '');
    params.set('scenario', localStorage.getItem('download-pdf-scenario') ?? '');
    params.set('date', localStorage.getItem('download-pdf-date') ?? '');
    get<DownloadPdfResponse>('/pdf', params)
      .then((res: AxiosResponse<DownloadPdfResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          window.open(
            res.data.reportUrl,
            '_self',
            'noreferrer',
          );
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      }).finally(() => {
        storageType(process.env.REACT_APP_STORAGE ?? '').removeItem(
          'download-pdf-department',
        );
        storageType(process.env.REACT_APP_STORAGE ?? '').removeItem(
          'download-pdf-scenario',
        );
        storageType(process.env.REACT_APP_STORAGE ?? '').removeItem(
          'download-pdf-date',
        );
      });
  }
  if(localStorage.getItem('download-pdf-inspection') != null) {
    params.set('inspection', localStorage.getItem('download-pdf-inspection') ?? '');
    get<DownloadPdfResponse>('/pdf', params)
      .then((res: AxiosResponse<DownloadPdfResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          window.open(
            res.data.reportUrl,
            '_self',
            'noreferrer',
          );
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      }).finally(() => {
        storageType(process.env.REACT_APP_STORAGE ?? '').removeItem(
          'download-pdf-inspection',
        );
      });
  }
  if(localStorage.getItem('download-pdf-round') != null) {
    params.set('round', localStorage.getItem('download-pdf-round') ?? '');
    get<DownloadPdfResponse>('/pdf', params)
      .then((res: AxiosResponse<DownloadPdfResponse, any> | undefined): void => {
        if(res?.data !== undefined) {
          window.open(
            res.data.reportUrl,
            '_self',
            'noreferrer',
          );
        }
      }).catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      }).finally(() => {
        storageType(process.env.REACT_APP_STORAGE ?? '').removeItem(
          'download-pdf-round',
        );
      });
  }
};
