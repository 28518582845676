import type Loading from '../../services/LoadingSpinner/Loading';
import type { ColorMode } from '../../services/Theme/ThemeContext';
import type { User } from '../../services/User/User';
import type { Round, Schedule } from '../../utils/lists';
import type { ErrorRespose, RoundResponse, TestingSubtype, TestingTypeWithSubtypes } from '../../utils/response';
import type { Theme } from '@mui/material';
import type { AxiosError, AxiosResponse } from 'axios';
import type { Country } from 'country-code-lookup';
import type { Dayjs } from 'dayjs';
import type { ChangeEvent } from 'react';
import type { ControllerFieldState, ControllerRenderProps, SubmitHandler } from 'react-hook-form';

import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Box, Button, Card, FormControl, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Typography, styled } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import lookup from 'country-code-lookup';
import Holidays from 'date-holidays';
import dayjs from 'dayjs';
import { type Dispatch, type MouseEvent, type SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import GivenchyLogo from '../../assets/images/givenchy.svg';
import { deleteData, put } from '../../services/Api/ApiFunctions';
import useLoadingSpinnerContext from '../../services/LoadingSpinner/LoadingSpinnerService';
import { useColorMode } from '../../services/Theme/ThemeContext';
import useUser from '../../services/User/UserService';
import storageType from '../../utils/storageService';

import ScheduleConfirmDialog from '../dialogs/ScheduleConfirmDialog/ScheduleConfirmDialog';
import { Status } from '../../utils/statuses';

interface RoundListProps {
  type: string;
  roundListResponse: RoundResponse | undefined;
  emptyMessageCode: string;
  pageNumber: number;
  typesList: TestingTypeWithSubtypes[] | undefined;
  inspectorsList: InspectorSelect[];
  setPageNumber: Dispatch<SetStateAction<number>>;
  fetchTable: () => void;
  fetchTabs: () => void;
}

interface TableRowProps {
  round: Round;
  type: string;
  inspectorsList: InspectorSelect[];
  typesList: TestingTypeWithSubtypes[] | undefined;
  fetchTable: () => void;
  fetchTabs: () => void;
}
interface InspectorSelect {
  id: number;
  name: string;
  surname: string;
}

interface FormValues {
  name: string;
  surname: string;
  email: string;
  quantity: number;
  date: Dayjs | null;
  time: Dayjs | null;
  orderitemId?: number;
  typeId?: number;
  subtypeId?: string;
  inspectorId: string;
}

interface ScheduleNotValidResponse {
  count: number;
  sum: number;
  overflow: boolean;
}

interface SchedulationError {
  response: ScheduleNotValidResponse;
  status: number;
  options: any;
  message: string;
  name: string;
}

interface InspectionData {
  date: string
  department_id: number,
  id: number,
  inspector_id: number,
  is_locked: boolean,
  last_edit_date:string,
  report_url: string | null,
  scenario_id: number,
  signature_url: string |null,
  supplier_id: number,
}

interface SupplierInspectionsInfo {
  count: number,
  overflow: boolean,
  sum: number
}

interface CloseSelfTestingResponse {
  reportUrl: string;
}

const StyledTableRow: any = styled(TableRow)(({ theme }:{ theme: Theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function RoundList({ type, emptyMessageCode, inspectorsList, typesList, roundListResponse, pageNumber, setPageNumber, fetchTable, fetchTabs }: RoundListProps): JSX.Element {
  const { t } = useTranslation();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const [roundList, setRoundList] = useState<Round[] | undefined>();

  const handleChangePage = (event: MouseEvent<HTMLElement> | null, page: number) : void => {
    setPageNumber(page);
  };

  useEffect(() => {
    loadingSpinner.addRequest();
    if(roundListResponse?.paginationInfo.count === 0 && roundList !== undefined && roundList.length !== 0 && pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
    setRoundList(roundListResponse?.items);
    loadingSpinner.addResponse();
  }, [roundListResponse]);

  return (
    roundList !== undefined && roundList.length !== 0 ?
      <Card sx={{ boxShadow: 3, borderRadius: '10px' }}>
        <Box sx={{ width: '100%' }}>
          <TableContainer>
            <Table size="small" sx={{ overflowX:'hidden', minWidth:'840px' }}>
              <TableBody>
                {roundList.map((round: Round) => (
                  <TableRowForm key={round.id} inspectorsList={inspectorsList} round={round} type={type} typesList={typesList} fetchTable={fetchTable} fetchTabs={fetchTabs} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={Math.ceil(roundListResponse?.totalCount ?? 0 / 10)}
            rowsPerPage={roundListResponse?.paginationInfo.limit ?? 0}
            page={pageNumber}
            onPageChange={handleChangePage}
          />
        </Box>
      </Card>
      : <Typography variant="h3" color='#8f9bb3'>{t(emptyMessageCode)}</Typography>

  );
};
function TableRowForm ({ typesList, inspectorsList, round, type, fetchTable, fetchTabs }: TableRowProps): JSX.Element {
  const { t } = useTranslation();
  const user: User = useUser();
  const theme: ColorMode = useColorMode();
  const loadingSpinner: Loading = useLoadingSpinnerContext();
  const country: Country | null = lookup.byCountry(user.active_role?.role === 'SUPPLIER' ? user.active_role.supplier?.country ?? '' : round.orderitem.supplier.country);
  const hd: Holidays = new Holidays(country?.iso2 ?? '');
  const [openScheduleConfirmDialog, setOpenScheduleConfirmDialog] = useState(false);
  const [confirmDialogInspections, setConfirmDialogInspection] = useState<InspectionData[]>();
  const [supplierInspectionsInfo, setSupplierInspectionsInfo] = useState<SupplierInspectionsInfo>();
  const [scheduledDate, setScheduledDate] = useState<Dayjs | null>();

  const { register, handleSubmit, reset, watch, trigger, getValues, setValue, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'all',
    criteriaMode: 'all',
  });

  useEffect(() => {
    trigger().then(() => ({})).catch(() => ({}));
  }, []);

  const handleDelete = (orderitemId: number) : void => {
    deleteData(`/testing-rounds/${orderitemId}`)
      .then((): PromiseLike<void> | void => {
        toast.success(t('round_delete_successful'));
        fetchTabs();
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  const onSubmitConfirmDialog = (date?: string, isDateNotMerge?: boolean): void => {
    let dateWithHour: Dayjs | null = date != null ? dayjs(date) : getValues().date;
    const forceUpdateCoordinator: URLSearchParams | undefined = (isDateNotMerge != null && isDateNotMerge) ? new URLSearchParams({ 'forceUpdate':'true' }) : undefined;

    if(date === undefined && getValues().time != null) {
      dateWithHour = dayjs(dateWithHour?.utcOffset(2, true).startOf('date').hour(getValues().time?.hour() ?? 0).minute(getValues().time?.minute() ?? 0).format());
    }

    if(user.active_role?.role === 'SUPPLIER') {
      put(`/testing-rounds/${round.id}/supplier`, { 'date': getValues().date?.utcOffset(2, true).startOf('date').format() ?? round.date, 'quantity': getValues().quantity }, new URLSearchParams({ 'forceUpdate':'true' }))
        .then(() => {
          toast.success(t('schedule_new_round_success'));
          loadingSpinner.addRequest();
          fetchTabs();
          loadingSpinner.addResponse();
        })
        .catch((error: AxiosError) => {
          const schedulationError:SchedulationError | undefined = error.response?.data as SchedulationError | undefined;
          if(schedulationError !== undefined) {
            if(Array.isArray(schedulationError.message)) {
              setOpenScheduleConfirmDialog(true);
            } else {
              toast.error(t(schedulationError.message.toLowerCase()));
            }
          } else {
            const errorData: ErrorRespose = error.response?.data as ErrorRespose;
            console.log('error', errorData);
            toast.error(t(errorData.message.toLowerCase()));
          }
        });
    }

    if(user.active_role?.role === 'COORDINATOR') {
      put(`/testing-rounds/${round.id}/coordinator`, { 'date': dateWithHour?.format(), 'quantity': getValues().quantity, 'typeId': getValues().typeId, 'subtypeId': getValues().typeId === 2 ? null: getValues().subtypeId, 'inspectorId': getValues().inspectorId }, forceUpdateCoordinator)
        .then(() => {
          toast.success(t('schedule_new_round_success'));
          loadingSpinner.addRequest();
          fetchTabs();
          loadingSpinner.addResponse();
        })
        .catch((error: AxiosError) => {
          const schedulationError: SchedulationError | undefined = error.response?.data as SchedulationError | undefined;
          if(schedulationError !== undefined) {
            if(Array.isArray(schedulationError.message)) {
              setOpenScheduleConfirmDialog(true);
            } else {
              toast.error(t(schedulationError.message.toLowerCase()));
            }
          } else {
            const errorData: ErrorRespose = error.response?.data as ErrorRespose;
            console.log('error',errorData);
            toast.error(t(errorData.message.toLowerCase()));
          }
        });
    }
  };

  const findSelectedType = (types: TestingTypeWithSubtypes[]) : TestingSubtype[] | [] => {
    if (types.findIndex((typ: TestingTypeWithSubtypes) => typ.id === Number(getValues().typeId)) !== -1) {
      const index: number = types.findIndex((typ: TestingTypeWithSubtypes) => typ.id === Number(getValues().typeId));

      return types[index].subtypes;
    } else {
      return [];
    }
  };

  const disableDatePickerDates= (day: dayjs.Dayjs): boolean => {
    if(dayjs(round.inspection?.date).startOf('date').format() < dayjs().startOf('date').format()) {
      return day.startOf('date').format() < dayjs().startOf('date').format() || (Boolean(hd.isHoliday(day.startOf('date').format())) || [0].includes(day.day()));
    }else{
      return day.startOf('date').format() < dayjs(round.inspection?.date).startOf('date').format() || (Boolean(hd.isHoliday(day.startOf('date').format())) || [0].includes(day.day()));
    }
  };

  const disableDateBeforeToday= (day: dayjs.Dayjs): boolean => {
    return day.startOf('date').format() < dayjs().startOf('date').format() || (Boolean(hd.isHoliday(day.startOf('date').format())) || [0].includes(day.day()));
  };

  const disableTimePickerDates= (day: dayjs.Dayjs): boolean => {
    if(dayjs(watch().date).startOf('date').format() < dayjs().startOf('date').format()) {
      return day.startOf('date').format() < dayjs().startOf('date').format() || (Boolean(hd.isHoliday(day.startOf('date').format())) || [0].includes(day.day()));
    }else{
      return day.startOf('date').format() < dayjs(watch().date).startOf('date').format() || (Boolean(hd.isHoliday(day.startOf('date').format())) || [0].includes(day.day()));
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (formValue: FormValues): void => {
    let dateWithHour: Dayjs | null = getValues().date;

    if(getValues().time != null) {
      dateWithHour = dayjs(dateWithHour?.utcOffset(2, true).startOf('date').hour(getValues().time?.hour() ?? 0).minute(getValues().time?.minute() ?? 0).format());
    }

    if(user.active_role?.role === 'SUPPLIER') {
      if(type === 'self') {
        put(`/testing-rounds/${round.id}/close-selftesting`, { 'quantity': formValue.quantity })
          .then((res: AxiosResponse<CloseSelfTestingResponse, any> | undefined) => {
            toast.success(t('complete_round_success'));
            window.open(res?.data.reportUrl, '_blank', 'noreferrer');
            loadingSpinner.addRequest();
            fetchTabs();
            loadingSpinner.addResponse();
          })
          .catch((error: AxiosError) => {
            const errorData: ErrorRespose = error.response?.data as ErrorRespose;
            console.log('error',errorData);
            toast.error(t(errorData.message.toLowerCase()));
          });
      }else{
        put(`/testing-rounds/${round.id}/supplier`, { 'date': formValue.date?.utcOffset(2, true).startOf('date').format() ?? round.date, 'quantity': formValue.quantity })
          .then(() => {
            toast.success(t('schedule_new_round_success'));
            loadingSpinner.addRequest();
            fetchTabs();
            loadingSpinner.addResponse();
          })
          .catch((error: AxiosError) => {
            const schedulationError: SupplierInspectionsInfo | undefined = error.response?.data as SupplierInspectionsInfo | undefined;
            if(schedulationError !== undefined) {
              const similarInspections :SupplierInspectionsInfo = schedulationError;
              setSupplierInspectionsInfo(similarInspections);
              setOpenScheduleConfirmDialog(true);
            } else {
              const errorData: ErrorRespose = error.response?.data as ErrorRespose;
              console.log('error',errorData);
              toast.error(t(errorData.message.toLowerCase()));
            }
          });
      }
    }

    if(user.active_role?.role === 'COORDINATOR') {
      put(`/testing-rounds/${round.id}/coordinator`, { 'date': dateWithHour?.format(), 'typeId': formValue.typeId ?? '', 'subtypeId': formValue.typeId === 2 ? null: formValue.subtypeId, 'inspectorId': formValue.inspectorId })
        .then(() => {
          toast.success(t('schedule_new_round_success'));
          loadingSpinner.addRequest();
          if(type === 'new') {
            reset();
          }
          fetchTabs();
          loadingSpinner.addResponse();
        })
        .catch((error: AxiosError) => {
          const schedulationError: SchedulationError | undefined = error.response?.data as SchedulationError | undefined;
          if(schedulationError !== undefined) {
            if(Array.isArray(schedulationError.message)) {
              const similarInspections :InspectionData[] = schedulationError.message;
              setScheduledDate(dayjs(getValues().date?.utcOffset(2, true).startOf('date').hour(getValues().time?.hour() ?? 0).minute(getValues().time?.minute() ?? 0).format()));
              setConfirmDialogInspection(similarInspections);
              setOpenScheduleConfirmDialog(true);
            } else {
              toast.error(t(schedulationError.message.toLowerCase()));
            }
          } else {
            const errorData: ErrorRespose = error.response?.data as ErrorRespose;
            console.log('error',errorData);
            toast.error(t(errorData.message.toLowerCase()));
          }
        });
    }
  };

  const setDefaultDate = (): Dayjs | null | undefined => {
    if(type === 'new' || type === 'locked') {
      if(dayjs(round.date) > dayjs()) {
        return dayjs(round.date);
      }else if([0].includes(dayjs().day())) {
        return dayjs().add(1,'day');
      }else{
        return dayjs();
      }
    }else{
      return dayjs(round.inspection?.date);
    }
  };

  return (
    <StyledTableRow key={round.id}>
      <TableCell align='left' sx={{ p: 3 }}>
        <Grid container columnSpacing={5} rowSpacing={2}>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('orderitemcode_field_label')}</Typography>
            <Typography>{round.orderitem.number}</Typography>
          </Grid>
          <Grid item md={12} lg={2}>
            <Typography fontWeight={'bold'}>{t('testinground_row_label')}:</Typography>
            <Typography>{round.orderitem.item}</Typography>
          </Grid>
          {
            user.active_role?.role === 'COORDINATOR' ? (
              <Grid item md={12} lg={6}>
                <Typography fontWeight={'bold'}>{t('deliverydate_field_label')}</Typography>
                <Typography>{dayjs(round.orderitem.deliveryDate).utc().local().format('DD/MM/YYYY')}</Typography>
              </Grid>) : null
          }
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('testinground_round_label')}:</Typography>
            {(type === 'locked' || type === 'editable') ?
              <div onClick={(): void => {
                if (round.previousReportUrl) {
                  window.open(round.previousReportUrl, '_blank', 'noreferrer');
                }
              }} style={{ cursor: round.previousReportUrl ? 'pointer' : 'cursor' }}>
                <Typography color={round.number > 1 ? '#F44336' : theme.mode === 'light' ? '#131B20' : '#FFFFFF'}>{round.number}<sup style={{ fontSize:'60%' }}>o</sup></Typography>
              </div>
              :
              <Typography color={round.number > 1 ? '#F44336' : theme.mode === 'light' ? '#131B20' : '#FFFFFF'}>{round.number}<sup style={{ fontSize:'60%' }}>o</sup></Typography>
            }
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('testedquantity_field_label')}:</Typography>
            <Typography>{`${round.orderitem.requiredFromSupplierQuantity} / ${round.orderitem.orderedQuantity}`}</Typography>
          </Grid>
          { user.active_role?.role === 'COORDINATOR' && (
            <Grid item md={12} lg={6}>
              <Typography fontWeight={'bold'}>{t('supplier_label')}:</Typography>
              <Typography>{round.orderitem.supplier.description}</Typography>
            </Grid>
          )}
        </Grid>
      </TableCell>
      <TableCell align='center' sx={{ p: 3, display: { xs: 'none', md: 'table-cell' } }}>
        <div onClick={(): void => {
          if (round.orderitem.sku.imageUrl) {
            window.open(`${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${round.orderitem.sku.imageUrl}&token=${storageType(
              process.env.REACT_APP_STORAGE ?? 'local',
            ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`, '_blank', 'noreferrer');
          }
        }} style={{ cursor: round.orderitem.sku.imageUrl ? 'pointer' : 'cursor' }}>
          <Avatar sx={{ width: 75, height: 75, backgroundColor: `${round.orderitem.sku.imageUrl !== '' ? '#F6F6F6' : ''}` }}>
            {
              <img width="70px" alt='givenchy logo'
                onError={(e: any): void => {
                  e.target.onerror = null; // prevents looping
                  e.target.src=GivenchyLogo;
                  e.target.style.width='40px';
                }}
                src={`${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${round.orderitem.sku.imageUrl}&token=${storageType(
                  process.env.REACT_APP_STORAGE ?? 'local',
                ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`} />
            }
          </Avatar>
        </div>
      </TableCell>
      <TableCell align='left' sx={{ p: 3 }}>
        <Grid container spacing={1}>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('testinground_line_label')}:</Typography>
            <Typography>{round.orderitem.sku.line}</Typography>
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('dashboard_sku_label')}:</Typography>
            <Typography>{round.orderitem.sku.code}{round.orderitem.sku.color.code}</Typography>
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('description_field_label')}</Typography>
            <Typography>{round.orderitem.sku.description}</Typography>
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('testinground_colordescription_label')}:</Typography>
            <Typography>{round.orderitem.sku.color.description}</Typography>
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('testinground_collectiondescription_label')}:</Typography>
            <Typography>{`${round.orderitem.collection.description} (${round.orderitem.collection.code})`}</Typography>
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography fontWeight={'bold'}>{t('size_field_label')}:</Typography>
            <Typography>{round.orderitem.schedules.length === 0 ? t('testinground_uniquesize_label'): round.orderitem.schedules.map((schedule: Schedule) => schedule.size).join(', ').length !== 0 ? round.orderitem.schedules.map((schedule: Schedule) => schedule.size).join(', ') : t('testinground_uniquesize_label')}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align='right' sx={{ p: 3 }}>
        <form>
          {(type === 'locked' && user.active_role?.role === 'SUPPLIER') &&
            <>
              <Typography fontWeight={'bold'} sx={{ mb: 2, whiteSpace: 'break-spaces' }}>{`${t('quantity_field_label')} ${round.declaredQuantity}`} </Typography>
              <FormControl sx={{ mb: 2 }}>
                <Controller
                  name="date"
                  defaultValue={setDefaultDate()}
                  rules={{
                    required: true,
                    validate: (value: Dayjs | null) => value != null ? !disableDateBeforeToday(value) : true,
                  }}
                  control={control}
                  render={({ field ,fieldState: { error, invalid } }: {field: ControllerRenderProps<FormValues, 'date'>, fieldState: ControllerFieldState}): JSX.Element => (
                    <DatePicker
                      {...register('date')}
                      {...field}
                      format='DD/MM/YYYY'
                      shouldDisableDate={disableDateBeforeToday}
                      onChange={async (e: any): Promise<void> => {
                        field.onChange(e);
                        await trigger();
                      }}
                      slotProps={{
                        textField: {
                          error: (error != null || errors.date != null || invalid),
                          helperText: ((error?.type === 'required' || errors.date?.type === 'required') && t('error_requiredfield_helpertext') || (error?.type === 'validate' || errors.date?.type === 'validate') && t('date_not_greater_than_that_into_round')),
                          label: t('testinground_requestdate_label'),
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
              <Box>
                <Button
                  sx={{ minWidth:'50px', mr:1 }}
                  variant='contained'
                  onClick={handleSubmit((data: FormValues)=>
                    onSubmit({ ...data, orderitemId: round.orderitem.id }))}
                  disabled={!isValid}
                  color='primary'>
                  {t('update_action')}
                </Button>
              </Box>
            </>
          }
          {(type === 'editable' && user.active_role?.role === 'SUPPLIER') &&
          <>
            {round.inspection?.isLocked &&
              <Box sx={{ pl:1 }}>
                <LockIcon sx={{ color:'#FF3B30' }} />
              </Box>
            }
            <FormControl>
              <Controller
                name="quantity"
                defaultValue={round.declaredQuantity}
                control={control}
                disabled={round.status === Status.DONE || round.number > 1 || round.inspection?.isLocked}
                render={({ field, fieldState: { error } }: {field: ControllerRenderProps<FormValues, 'quantity'>, fieldState: ControllerFieldState}): JSX.Element =>
                  <TextField
                    {...register('quantity', { required: true, max: (round.orderitem.orderedQuantity-((round.orderitem.requiredFromSupplierQuantity)-(round.declaredQuantity))), min: 1 })}
                    {...field}
                    sx={{ mb: 2, minWidth:'50px' }}
                    InputProps={{
                      inputProps: { min: 1, max: (round.orderitem.orderedQuantity - ((round.orderitem.requiredFromSupplierQuantity) - (round.declaredQuantity))) },
                      readOnly: (round.status === Status.DONE || round.number > 1 || round.inspection?.isLocked),
                    }}
                    type='number'
                    variant="outlined"
                    label={t('quantity_field_label')}
                    fullWidth
                    placeholder='0'
                    autoComplete="quantity"
                    error={error != null}
                    helperText={errors.quantity?.type === 'required' ? t('error_requiredfield_helpertext') : errors.quantity?.type === 'max' || errors.quantity?.type === 'min' ? t('error_overflow_quantity') : ''}
                  />}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="date"
                defaultValue={dayjs(round.date)}
                rules={{
                  required: true,
                  validate: (value: Dayjs | null) => value != null ? !disableDatePickerDates(value) : true,
                }}
                control={control}
                render={({ field, fieldState: { error, invalid } }: {field: ControllerRenderProps<FormValues, 'date'>, fieldState: ControllerFieldState}): JSX.Element => (
                  <DatePicker
                    {...register('date')}
                    {...field}
                    disabled={round.status === Status.DONE || round.inspection?.isLocked}
                    sx={{ mb: 2 }}
                    format='DD/MM/YYYY'
                    readOnly={round.status === Status.DONE || round.inspection?.isLocked}
                    shouldDisableDate={disableDateBeforeToday}
                    onChange={async (e: any): Promise<void> => {
                      field.onChange(e);
                      await trigger();
                    }}
                    slotProps={{
                      textField: {
                        error: (error != null || errors.date != null || invalid),
                        helperText: ((error?.type === 'required' || errors.date?.type === 'required') && t('error_requiredfield_helpertext') || (error?.type === 'validate' || errors.date?.type === 'validate') && t('date_not_greater_than_that_into_round')),
                        label: t('testinground_requestdate_label'),
                      } }}
                  />
                )}
              />
            </FormControl>
            <Box>
              <Button
                sx={{ minWidth:'50px', mr:1 }}
                variant='contained'
                onClick={handleSubmit((data: FormValues)=>
                  onSubmit({ ...data, orderitemId: round.orderitem.id }))}
                disabled={!isValid || round.status === Status.DONE || round.inspection?.isLocked}
                color='primary'>
                {t('update_action')}
              </Button>
              {round.number === 1 && <Button sx={{ minWidth:'50px' }} disabled={round.status === Status.DONE || round.inspection?.isLocked} variant='contained' color='error' onClick={(): void => handleDelete(round.id)} >{t('delete_action')}</Button>}
            </Box>
          </>
          }
          {(type === 'self' && user.active_role?.role === 'SUPPLIER') &&
            <Grid container spacing={1}>
              <Grid item lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center', gap: 1, px: 3 }}>
                  <Typography fontWeight={'bold'}>{t('quantity_field_label')}</Typography>
                  <Typography>{round.declaredQuantity}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center', gap: 1 }}>
                  <Typography fontWeight={'bold'} sx={{ whiteSpace: 'break-spaces' }}>{t('testinground_requestdate_label')}:</Typography>
                  <Typography>{dayjs(round.inspection?.date).utc().local().format('DD/MM/YYYY')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <Controller
                    name="quantity"
                    defaultValue={round.realQuantity}
                    control={control}
                    render={({ field, fieldState: { error } }: {field: ControllerRenderProps<FormValues, 'quantity'>, fieldState: ControllerFieldState}): JSX.Element =>
                      <TextField
                        {...field}
                        {...register('quantity', { required: true, max: (round.declaredQuantity), min: 1 })}
                        sx={{ my: 2, minWidth:'50px' }}
                        InputProps={{ inputProps: { min: 1, max: (round.declaredQuantity) } }}
                        type='number'
                        variant="outlined"
                        label={t('quantity_field_label')}
                        fullWidth
                        placeholder='0'
                        autoComplete="quantity"
                        error={error != null}
                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                          field.onChange(e.target.value ? Number(e.target.value) : '');
                        }}
                        helperText={errors.quantity?.type === 'required' ? t('error_requiredfield_helpertext') : errors.quantity?.type === 'max' || errors.quantity?.type === 'min' ? t('error_overflow_quantity') : ''}
                      />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{ minWidth:'50px' }}
                  variant='contained'
                  disabled={!isValid}
                  color='primary'
                  onClick={handleSubmit((data: FormValues)=>
                    onSubmit({ ...data, orderitemId: round.orderitem.id }))}>
                  {t('selfschedule_action')}
                </Button>
              </Grid>
            </Grid>
          }
          {((type === 'locked' || type === 'editable' || type === 'new') && user.active_role?.role === 'COORDINATOR') &&
            <Grid container spacing={1}>
              <Grid item lg={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center', gap: 1, px: 3 }}>
                  <Typography fontWeight={'bold'}>{t('quantity_field_label')}</Typography>
                  <Typography>{round.declaredQuantity}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection:'row', justifyContent:'center', alignContent:'center', alignItems:'center', gap: 1 }}>
                  <Typography fontWeight={'bold'} sx={{ whiteSpace: 'break-spaces' }}>{t('testinground_requestdate_label')}:</Typography>
                  <Typography>{dayjs(round.date).utc().local().format('DD/MM/YYYY')}</Typography>
                </Box>
                {round.inspection?.isLocked &&
                <Box sx={{ pl:1 }}>
                  <LockIcon sx={{ color:'#FF3B30' }} />
                </Box>}
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl fullWidth sx={{ my: 2 }}>
                  <Controller
                    name="typeId"
                    control={control}
                    defaultValue={round.testingTypeId}
                    render={({ field, fieldState: { error } }: {field: ControllerRenderProps<FormValues, 'typeId'>, fieldState: ControllerFieldState}): JSX.Element => (
                      <TextField
                        {...register('typeId', { required: true })}
                        {...field}
                        disabled={round.status === Status.DONE || round.inspection?.isLocked}
                        select
                        variant="outlined"
                        label={t('input_type_label')}
                        error={error != null}
                        helperText={errors.typeId?.type === 'required' && t('error_requiredfield_helpertext')}
                        InputProps={{
                          readOnly: (round.status === Status.DONE || round.inspection?.isLocked),
                        }}
                        onChange={async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
                          field.onChange(e.target.value ? Number(e.target.value) : '');
                          await trigger();
                        }}
                      >
                        {(typesList != null) && (typesList.length > 0) && typesList.map((ty: TestingTypeWithSubtypes) => {
                          return (
                            <MenuItem value={ty.id} key={ty.id}>
                              {t(`type_${ty.type.toLowerCase()}_label`)}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl
                  sx={{ my: 2, minWidth: 0 }}>
                  <Controller
                    name="subtypeId"
                    control={control}
                    defaultValue={round.testingSubtypeId != null ? round.testingSubtypeId.toString() : ''}
                    render={({ field }: {field: ControllerRenderProps<FormValues, 'subtypeId'>, fieldState: ControllerFieldState}): JSX.Element =>(
                      <TextField
                        {...register('subtypeId', { required: watch().typeId === 1 || watch().typeId === 3 })}
                        {...field}
                        select
                        variant="outlined"
                        sx={{ minWidth: 0 }}
                        label={t('input_subtype_label')}
                        disabled={watch().typeId == null || watch().typeId === 2 || watch().typeId === 4 || round.inspection?.isLocked}
                        error={errors.subtypeId != null}
                        helperText={(errors.subtypeId?.type === 'required') && t('error_requiredfield_helpertext')}
                        InputProps={{
                          readOnly: (round.status === Status.DONE || round.inspection?.isLocked),
                        }}
                        onChange={async(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
                          field.onChange(e.target.value);
                          await trigger();
                        }}
                      >
                        {(typesList != null) && (typesList.length > 0) && findSelectedType(typesList).map((subtype: TestingSubtype) => {
                          return (
                            <MenuItem value={subtype.id} key={subtype.id}>
                              {subtype.subtype}
                            </MenuItem>
                          );
                        })}
                      </TextField>)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ my: 2 }}>
                  <Controller
                    name="inspectorId"
                    control={control}
                    defaultValue={ round.inspection?.inspector.id.toString() != null ? round.inspection.inspector.id.toString() : inspectorsList.length !== 0 ? inspectorsList[0]?.id.toString() : ''}
                    render={({ field, fieldState: { error } }: {field: ControllerRenderProps<FormValues, 'inspectorId'>, fieldState: ControllerFieldState}): JSX.Element => (
                      <TextField
                        {...register('inspectorId', { required: true })}
                        {...field}
                        disabled={Number(watch().typeId) === 4 || round.inspection?.isLocked}
                        select
                        variant="outlined"
                        label={t('inspector_label')}
                        InputProps={{
                          readOnly: (round.status === Status.DONE || round.inspection?.isLocked),
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
                          field.onChange(e.target.value ? Number(e.target.value) : '');
                        }}
                        error={error != null}
                        helperText={errors.inspectorId?.type === 'required' && t('error_requiredfield_helpertext')}
                      >
                        {inspectorsList.map((inspector: InspectorSelect) => {
                          return (
                            <MenuItem value={inspector.id} key={inspector.id}>
                              {inspector.name + ' ' + inspector.surname}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={8}>
                <FormControl sx={{ my: 2 }}>
                  <Controller
                    name="date"
                    defaultValue={setDefaultDate()}
                    rules={{
                      required: true,
                      validate: (value: Dayjs | null) => value != null ? !(type === 'editable' ? disableDateBeforeToday(value) : (disableDatePickerDates(value))) : true,
                    }}
                    control={control}
                    render={({ field ,fieldState: { error, invalid } }: {field: ControllerRenderProps<FormValues, 'date'>, fieldState: ControllerFieldState}): JSX.Element => (
                      <DatePicker
                        {...register('date')}
                        {...field}
                        disabled={Number(watch().typeId) === 4 || round.inspection?.isLocked}
                        format='DD/MM/YYYY'
                        readOnly={(round.status === Status.DONE || round.inspection?.isLocked)}
                        shouldDisableDate={type === 'editable' ? disableDateBeforeToday : disableDatePickerDates}
                        onChange={async (e: any): Promise<void> => {
                          field.onChange(e);
                          await trigger();
                        }}

                        slotProps={{
                          textField: {
                            error: (error != null || errors.date != null || invalid),
                            helperText: ((error?.type === 'required' || errors.date?.type === 'required') && t('error_requiredfield_helpertext') || (error?.type === 'validate' || errors.date?.type === 'validate') && t('date_not_greater_than_that_into_round')),
                            label: t('inspection_date_label'),
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl sx={{ my: 2, minWidth: 0 }}>
                  <Controller
                    name="time"
                    defaultValue={type !== 'new' && round.inspection?.date != null ? dayjs(round.inspection.date).startOf('date').hour(dayjs(round.inspection.date).hour()).minute(dayjs(round.inspection.date).minute()): dayjs().startOf('date').hour(9).minute(0).second(0).millisecond(0)}
                    control={control}
                    render={({ field, fieldState: { error, invalid } }: {field: ControllerRenderProps<FormValues, 'time'>, fieldState: ControllerFieldState}): JSX.Element => (
                      <TimePicker
                        {...register('time', {
                          required: true,
                          validate: () => type !== 'new' ? !disableTimePickerDates(dayjs(watch().date?.hour(field.value?.hour() ?? 0).minute(field.value?.minute() ?? 0).second(0).millisecond(0))) : undefined,
                        })}
                        {...field}
                        disabled={Number(watch().typeId) === 4 || round.inspection?.isLocked}
                        ampm={false}
                        readOnly={(round.status === Status.DONE || round.inspection?.isLocked)}
                        onChange={async (e: any): Promise<void> => {
                          field.onChange(e);
                          await trigger();
                        }}
                        slotProps={{
                          textField: {
                            sx: { minWidth: 0 },
                            error: (error != null || errors.time != null || invalid),
                            helperText: errors.time?.type === 'required' && t('error_requiredfield_helpertext') || errors.time?.type === 'validate' && t('testinground_timenotvalid_label'),
                            label: t('inspection_hour_label'),
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {type === 'new' && (
                  <Button
                    variant="contained"
                    color='primary'
                    disabled={!isValid || round.status === Status.DONE || round.inspection?.isLocked}
                    onClick={handleSubmit((data: FormValues) =>
                      onSubmit({ ...data, orderitemId: round.orderitem.id }))}>
                    {t('schedule_action')}
                  </Button>
                )}
                {type === 'locked' && (
                  <Button
                    variant="contained"
                    color='primary'
                    disabled={!isValid || round.status === Status.DONE || round.inspection?.isLocked}
                    onClick={handleSubmit((data: FormValues) =>
                      onSubmit({ ...data, orderitemId: round.orderitem.id }))}>
                    {t('schedule_action')}
                  </Button>
                )}
                {type === 'editable' && (
                  <Button
                    variant="contained"
                    color='primary'
                    disabled={!isValid || round.status === Status.DONE || round.inspection?.isLocked}
                    onClick={handleSubmit((data: FormValues)=>
                      onSubmit({ ...data, orderitemId: round.orderitem.id }))
                    }>
                    {t('update_action')}
                  </Button>
                )}
              </Grid>
            </Grid>
          }
        </form>

        <ScheduleConfirmDialog
          open={openScheduleConfirmDialog}
          onClose={(): void => setOpenScheduleConfirmDialog(false)}
          inspections={confirmDialogInspections}
          scheduleInfo={supplierInspectionsInfo}
          scheduledDate={scheduledDate}
          fetchTable={fetchTable}
          onSubmitConfirmDialog={onSubmitConfirmDialog}
          setTableValue={setValue}
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default RoundList;
