import type {
  Inspection,
  NonCompliances,
  Resource,
  TestingRound,
} from '../../../utils/lists';
import type { ErrorRespose } from '../../../utils/response';
import type { AxiosError, AxiosResponse } from 'axios';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { type MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import GivenchyLogo from '../../../assets/images/givenchy.svg';
import { get } from '../../../services/Api/ApiFunctions';
import { brand, gray } from '../../../theme/theme';
import storageType from '../../../utils/storageService';

import DefectsDialog from '../DefectsDialog/DefectsDialog';
import ResourcesDialog from '../ResourcesDialog/ResourcesDialog';
import { Status } from '../../../utils/statuses';

interface UserInfoDialogProps {
  open: boolean;
  onClose: () => void;
  inspection: Inspection | undefined;
}

function InspectionDetailDialog({
  open,
  onClose,
  inspection,
}: UserInfoDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [openDefectsDialog, setOpenDefectsDialog] = useState<boolean>(false);
  const [defects, setDefects] = useState<NonCompliances[]>([]);
  const [openResourcesDialog, setOpenResourcesDialog] =
    useState<boolean>(false);
  const [resources, setResources] = useState<Resource[]>([]);

  const handleReportClick = (index: number): void => {
    window.open(
      inspection?.testingRoundList[index].reportUrl ?? '',
      '_blank',
      'noreferrer',
    );
  };

  const handleResourcesClick = (roundId: number): void => {
    get<Resource[]>(`/testing-rounds/${roundId}/resources`)
      .then((res: AxiosResponse<Resource[], any> | undefined): void => {
        if (res != null) {
          setResources(res.data);
          setOpenResourcesDialog(true);
        }
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  const handleNonCompliancesClick = (roundId: number): void => {
    get<NonCompliances[]>(`/testing-rounds/${roundId}/non-compliances`)
      .then((res: AxiosResponse<NonCompliances[], any> | undefined): void => {
        if (res != null) {
          setDefects(res.data);
          setOpenDefectsDialog(true);
        }
      })
      .catch((error: AxiosError) => {
        const errorData: ErrorRespose = error.response?.data as ErrorRespose;
        console.log(errorData);
        toast.error(t(errorData.message.toLowerCase()));
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(): void => onClose()}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={10}>
              <Typography fontWeight="bold" typography="h5" sx={{ pt: 1 }}>
                {t('inspection_detail_label')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                sx={{ float: 'right' }}
                onClick={(): void => onClose()}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {inspection?.testingRoundList.length === 0 && (
            <Typography>{t('testedroundnotfound_label')}</Typography>
          )}
          {inspection?.testingRoundList.map(
            (testedRound: TestingRound, index: number) => (
              <Accordion key={index} sx={{ boxShadow: 2, mb: '10px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      py: 1,
                    }}
                  >
                    <div
                      onClick={(event: MouseEvent<HTMLElement>): void => {
                        event.stopPropagation();
                        if (testedRound.orderitem.sku.imageUrl) {
                          window.open(
                            `${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${testedRound.orderitem.sku.imageUrl}&token=${storageType(
                              process.env.REACT_APP_STORAGE ?? 'local',
                            ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`,
                            '_blank',
                          );
                        }
                      }}
                      style={{
                        cursor: testedRound.orderitem.sku.imageUrl
                          ? 'pointer'
                          : 'cursor',
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          backgroundColor: `${testedRound.orderitem.sku.imageUrl !== '' ? '#F6F6F6' : ''}`,
                        }}
                      >
                        <img
                          width="40px"
                          alt="givenchy logo"
                          onError={(e: any): void => {
                            e.target.onerror = null; // prevents looping
                            e.target.src=GivenchyLogo;
                            e.target.style.width='25px';
                          }}
                          src={`${process.env.REACT_APP_ENDPOINT_ROOTAPI ?? ''}${testedRound.orderitem.sku.imageUrl}&token=${storageType(
                            process.env.REACT_APP_STORAGE ?? 'local',
                          ).getItem(`${process.env.REACT_APP_NAME ?? ''}-token`) ?? ''}`}
                        />
                      </Avatar>
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('orderlist_status_order_label')}:
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1,
                            alignContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              borderRadius: '50%',
                              backgroundColor: `${
                                testedRound.status === Status.DONE ? testedRound.result ?'#34C759' : '#FF3B30' :
                                  testedRound.status === Status.TO_DO ? brand[500] :
                                    testedRound.status === Status.DOING ? '#FFCC00' : gray[700]
                              }`,
                            }}
                          />
                          <Typography fontWeight={'bold'}>
                            {testedRound.status === Status.DONE ? testedRound.result ? t('status_completed_pass_label') : t('status_failed_label') :
                              testedRound.status === Status.TO_DO ? t('inspectionlist_todo_label') :
                                testedRound.status === Status.DOING ? t('status_inprogress_label') : t('status_cancelled_label')
                            }
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('ordercode_label')} ({t('testinground_row_label')}
                          ):
                        </Typography>
                        <Typography>
                          {testedRound.orderitem.number} (
                          {testedRound.orderitem.item})
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('dashboard_sku_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.orderitem.sku.code}
                          {testedRound.orderitem.sku.color.code}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('dashboard_skudescription_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.orderitem.sku.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('testinground_colordescription_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.orderitem.sku.color.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('testinground_collectiondescription_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.orderitem.collection.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      px: 4,
                      py: 1,
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('input_type_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.testingType.type === 'AQL' ||
                          testedRound.testingType.type === 'PERCENTAGE'
                            ? `${t(`type_${testedRound.testingType.type.toLowerCase()}_label`)} ${testedRound.testingSubtype.subtype}`
                            : t(
                              `type_${testedRound.testingType.type.toLowerCase()}_label`,
                            )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {testedRound.status === Status.DONE
                            ? t('inspection_realquantity_label')
                            : t('requestedquantity_field_label')}
                          :
                        </Typography>
                        <Typography>
                          {testedRound.realQuantity != null
                            ? testedRound.realQuantity
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {testedRound.status === Status.DONE
                            ? t('testinground_testingquanity_label')
                            : t('testinground_remainingquantity_label')}
                          :
                        </Typography>
                        <Typography>
                          {testedRound.revisionedQuantity != null
                            ? testedRound.revisionedQuantity
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('acceptedquantity_field_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.acceptedQuantity != null
                            ? testedRound.acceptedQuantity
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('koquantity_field_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.koQuantity != null
                            ? testedRound.koQuantity
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography fontWeight={'bold'}>
                          {t('failquantity_field_label')}:
                        </Typography>
                        <Typography>
                          {testedRound.blockedQuantity != null
                            ? testedRound.blockedQuantity
                            : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={testedRound.status !== Status.DONE}
                        onClick={(): void => handleReportClick(index)}
                        sx={{ minWidth: '140px' }}
                      >
                        {t('report_label')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={testedRound.resourcesCount === 0}
                        onClick={(): void =>
                          handleResourcesClick(testedRound.id)
                        }
                        sx={{ minWidth: '140px' }}
                      >
                        {t('resources_label')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          (testedRound.criticalNonCompliancesFound ?? 0) +
                            (testedRound.majorNonCompliancesFound ?? 0) +
                            (testedRound.minorNonCompliancesFound ?? 0) +
                            (testedRound.notCountedNonCompliancesFound ?? 0) ===
                          0
                        }
                        onClick={(): void =>
                          handleNonCompliancesClick(testedRound.id)
                        }
                        sx={{ minWidth: '140px' }}
                      >
                        {t('defectsdetail_label')}
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ),
          )}
        </DialogContent>
      </Dialog>
      <DefectsDialog
        open={openDefectsDialog}
        onClose={(): void => setOpenDefectsDialog(false)}
        defects={defects}
      />
      <ResourcesDialog
        open={openResourcesDialog}
        onClose={(): void => setOpenResourcesDialog(false)}
        resources={resources}
      />
    </>
  );
}

export default InspectionDetailDialog;
